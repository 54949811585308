<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1" :class="{'image-loading': !bg1ImageLoaded}">
    <!-- <div class="bg-1"> -->
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>
      <div v-if="!bg1ImageLoaded" class="main_loader">
        <div class="img-loader"></div>
      </div>
      <div class="h-md-30vh">&nbsp;</div>
      <section class="landing_view d-block">
        <div class="row d-flex justify-content-center">
          <div class="col-12 col-md-8">
            <h1 class="text-light large-text fnt-tny">{{ $t('ULTIMATE AI POWERED MARKETPLACE') }}</h1>
            <h3 class="text-light large-text-2">
              {{ $t('Experience Next-Level Business Growth with MOM') }}
              <br>
              {{ $t('The Future of AI-Driven Solutions') }}
            </h3>
            <div class="h-md-3vh"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-md-auto">
                  <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="userRedirect">{{ $t('MOM AI - ASK ANYTHING') }}</b-link>                
              </div>
              <div class="col-12 col-md-auto">
                  <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="shopRedirect">{{ $t('MOM Shop - SELL ONLINE') }}</b-link>                
              </div>
              <div class="col-12 col-md-auto">
                  <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="providerRedirect">{{ $t('MOM PROVIDER - BOOK ONLINE') }}</b-link>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-12">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="`${$apiUrl}/images/landing/google-play-store.png`" class="gplay_img" fluid alt="Google Play Store" />
                </b-link>
              </div>
              <div class="h-5vh"></div>
              <div class="col-12 col-md-8 d-none d-md-block">
                <p>
                   {{ $t('Are you ready to catapult your business into the era of digital innovation?') }}
                   {{ $t("MOM App is here to revolutionize your company's operations with cutting-edge AI tools and solutions") }}. {{ $t("Harness the intelligence of advanced technology to give your business the competitive edge it deserves") }}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div class="h-md-70vh h-20vh">&nbsp;</div>
      <section class="landing_view">
        <div class="row">
          <div class="col-12 col-md-8 mx-md-auto d-none d-md-block">
            <p>{{ $t('Step into Tomorrow with MOM App') }} - {{ $t('Your Digital Transformation Ally') }}</p>
          </div>
          <div class="col-12 col-md-8 mx-md-auto">
            <p>
              {{ $t('Dive into the Digital Age') }}: {{ $t("Embrace the full spectrum of MOM App's AI-powered features") }}, 
              {{ $t('from streamlined e-commerce to effective service management') }}. {{ $t('This robust platform is your complete solution for a modernized business model') }}.
            </p>
          </div>
          <div class="col-12 col-md-8 mx-md-auto">
            <p>
              {{ $t('Join the Progressive MOM Community') }}. 
              {{ $t("Connect with us and embark on your journey to business excellence") }}. {{ $t('Let MOM App lead the way in your digital transformation, providing a seamless transition into the innovative landscape of tomorrow') }}.
            </p>
          </div>
        </div>
        <div class="h-md-10vh">&nbsp;</div>
      </section>
    </div>

    
    <section class="landing_section">
      <div class="row mb-4 mb-md-1" v-for="(section, sk) in landingPageTiels" :key="`l_section_${sk}`">
        <div v-if="sk > 0" class="h-md-10vh"></div>
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row d-flex justify-content-around align-items-center">
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-10 mx-auto">
                  <b-img :src="`${$apiUrl}/images/landing/Website/${section.img}`" class="home_img" fluid :alt="section.imgAlt" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="row mb-md-3 mb-1 mt-2 mt-md-0">
                <div class="col-12">
                  <b-link class="custom-design text-light px-md-5 font-weight-bold" :to="{name: section.route}">{{ section.title }}</b-link>
                </div>
              </div>
              <div class="row">
                <div class="col-12" v-for="(section_para, spk) in section.paragraphs" :key="`${spk}_${sk}_sec_para`">
                  <p>{{ section_para }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-md-10vh"></div>
    </section>


    <div class="bg-2">
      <section class="landing_section">
        <div class="row">
          <div class="col-12 col-md-9 mx-md-auto">
            <h2 class="large-text-2 text-center">
              {{ $t('The MOM App Advantage: Holistic Tools for Unprecedented Success') }}
            </h2>
            <p>
              {{ $t('Data-Driven Insight') }}: {{ $t('Take advantage of our advanced analytics suite to make informed decisions that drive business growth and strategic direction') }}.
            </p>
            <p>
              {{ $t('Promotions and Customer Loyalty') }}: {{ $t('Attract and retain a loyal customer base with enticing promotion and loyalty programs tailored to your audience') }}.
            </p>
            <p>
              {{ $t('Expansive Market Outreach') }}: {{ $t('Whether you are targeting local customers or aiming for global expansion, MOM App equips you with the tools to reach and engage your desired market effectively') }}.
            </p>
          </div>
        </div>
        <div class="h-5vh h-md-30vh"></div>
        <div class="row">
          <div class="col-12 col-md-9 mx-md-auto">
            <h2 class="large-text-2">
              {{ $t('Why Opt for MOM App?') }}
            </h2>
            <p>
              {{ $t('Rapid, Versatile, and Easy-to-Adopt Solutions') }}
            </p>
            <p>
              {{ $t('Quick Setup') }}: {{ $t('Launch your online presence swiftly with our three-step setup process') }}.
            </p>
            <p>
              {{ $t('Adaptable Growth') }}: {{ $t('Whether you are a budding startup or an established enterprise, MOM App scales with your business, ensuring long-term adaptability and growth') }}.
            </p>
          </div>
        </div>
        <div class="h-5vh h-md-30vh"></div>
        <div class="row">
          <div class="col-12 col-md-9 mx-md-auto">
            <h1 class="large-text fnt-tny text-center text-uppercase">{{ $t('Welcome to the MOM App') }} - {{ $t('Your Gateway to AI-Enhanced Performance') }}</h1>
            <div class="h-md-5vh h-1vh"></div>
            <div class="row">
              <div class="col-12">
                <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="userRedirect">{{ $t('MOM AI - ASK ANYTHING') }}</b-link>
              </div>
              <div class="col-12">
                <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="shopRedirect">{{ $t('MOM Shop - SELL ONLINE') }}</b-link>
              </div>
              <div class="col-12">
                <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="providerRedirect">{{ $t('MOM PROVIDER - BOOK ONLINE') }}</b-link>
              </div>
              <div class="col-12 d-none d-md-block">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="`${$apiUrl}/images/landing/google-play-store.png`" class="gplay_img" fluid alt="Google Play Store"  />
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="h-md-90vh"></div>
      <LandingPageFooter :trasparentFooter="true"  />
      <AppFooterBlack :trasparentFooter="true"  />
    </div>
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingSVG from './LandingSVG.vue'
import { imgLoad } from '@/utilities'

export default {
  components: {
    LandingSVG,
    LandingPageFooter,
    AppFooterBlack,
    LandingPageHeader,
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  data: () => ({
    bg1ImageLoaded: false
  }),
  computed: {
    userRedirect: {
      get(){
        if(window.SuperApp.getters._isUser()){
          return {name: 'user-dashboard'}
        }
        return {name: 'apps-login'}
      }
    },
    shopRedirect: {
      get(){
        if (window.SuperApp.getters._isStore()){
          return { name: 'shop-dashboard' }
        }
        return {name: 'apps-shop-login'}
      }
    },
    providerRedirect: {
      get(){
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()){
          return { name: 'provider-requests'}
        }
        else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()){
          return { name: 'provider-agent-dashboard' }
        }
        return {name: 'apps-provider-login'}
      }
    },
    landingPageTiels: {
      get(){
        return [
          {
            img: 'MOM AI.webp',
            imgAlt: "MOM AI",
            title: this.$t('MOM AI - ASK ANYTHING'),
            route: 'apps-login',
            paragraphs: [
              `${this.$t('Engage Customers with AI: MOM AI is perfect for creating engaging and impactful content across various platforms like TikTok, Instagram, and your website')}. ${this.$t('Transform your marketing strategy with MOM AI, where innovation meets creativity')}.`,
              `${this.$t('Image Generation with Visual Brilliance: Create compelling, high-quality images in an')}. ${this.$t("instant with MOM App's powerful AI Image Generator")}, ${this.$t('and captivate your audience')}.`,
              `${this.$t("Voice Transformation Technology: With our text-to-speech and speech-to-text features, bridge the communication gap and speak directly to your customers in their preferred method of interaction")}.`,
              `${this.$t("Effortless Coding: Simplify your development processes with AI-generated code snippets")}, ${this.$t('enhancing productivity and reducing the time-to-market for your software')}.`,
            ],
          },
          {
            img: 'MOM Shop.webp',
            imgAlt: "MOM Shop",
            title: this.$t('MOM Shop - SELL ONLINE'),
            route: 'apps-shop-login',
            paragraphs: [
              this.$t("Order Management Made Easy: Take control of your orders with our user-friendly and powerful order management system") + ', ' + this.$t('allowing for increased efficiency') + '.',
              this.$t("Integrated Cloud POS: Seamlessly handle point-of-sale transactions with our cloud-based POS system") + ', ' + this.$t('ensuring smooth operations and accurate sales tracking') + '.',
              this.$t("Master HR Management: Streamline your workforce administration with our efficient HR management tools") + ', ' + this.$t('driving productivity and employee satisfaction') + '.',
              this.$t("Flawless Delivery Operations: Enjoy a hassle-free logistics experience with our smart delivery management system") + ', ' + this.$t('providing reliable and timely order fulfillment') + '.',
            ]
          },
          {
            img: 'MOM Service.webp',
            imgAlt: "MOM Service",
            title: this.$t('MOM PROVIDER - BOOK ONLINE'),
            route: 'apps-provider-login',
            paragraphs: [
                `
                  ${this.$t('Instant Visibility: Join our platform and instantly showcase your services to a vast online audience')}
                `,

                `
                  ${this.$t('Effortless Booking: Simplify service booking with our intuitive interface')}. ${this.$t('Customers can book your services in a few clicks')}, ${this.$t('thanks to our user-friendly design')}.
                `,

                `
                  ${this.$t('Secure Payments: Worried about payments? MOM Provider App ensures secure and hassle-free transactions')}, ${this.$t('with funds directly deposited into your company account')}
                `,

                `
                  ${this.$t('Data-Driven Growth: Make informed decisions with our powerful analytics tools')}. ${this.$t('Track performance')}, ${this.$t('analyze trends')}, ${this.$t('and refine your services for maximum profitability')}
                `
            ]
          },
        ]
      }
    }
  },
  methods: {
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
  },
  mounted(){
    const appUrl = process.env.VUE_APP_FRONT_END_BASE_URL
    const img = require('@/assets/images/bg-images/man-stands-front-mountain-looks-landscape.webp')
    const fullImageUrl = `${appUrl}${img}`
    imgLoad(fullImageUrl).then(r => {
      this.bg1ImageLoaded = true
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-color: #040612;
  background-image: url('~@/assets/images/bg-images/man-stands-front-mountain-looks-landscape.webp');
  background-position: center center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  background-size: cover; /* Ensures the image covers the entire element */
  padding-bottom: 1.5rem;
}


</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
@media screen and (min-width: 768px) {
  .bg-2 {
    background-position: center center; /* Centers the image */
    background-image: url('~@/assets/images/bg-images/man-is-walking-into-door-leading-magic-world.webp');
    background-repeat: no-repeat; /* Prevents repeating the image */
    background-size: cover; /* Ensures the image covers the entire element */
    color: #FFFFFF;
  }
}
</style>
